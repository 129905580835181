import * as React from 'react'
import Layout from '../components/Layout'
import LinkBar from '../components/LinkBar'

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet

//Global stylesheet
import "../components/styles/global.css"


const Technical = () => {
    return(
        <Layout pageTitle={"activities"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Technical details | bangbiangyang.aqq.xyz</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="Technical and website details for bangbiangyang.aqq.xyz."/>

                <meta property="og:title" content="Technical details | bangbiangyang.aqq.xyz"/>
                <meta property="og:description" content="Technical and website details for bangbiangyang.aqq.xyz."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/technical"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/technical">Technical details</Link>
            </LinkBar>

            <div className="midContentWrapper">
                Insert stuff.
            </div>

        </Layout>
    )
}

export default Technical